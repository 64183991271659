import React from "react"

import { useScrollToTop } from "../hooks/useScrollToTop"
import { Layout } from "../components/Layout"
import { Content, Home, Header, Title, Page } from "../components/PageContent"

const Poem: React.FC = () => {
  useScrollToTop()

  return (
    <Layout title="Muetry - The Kaleidoscope.">
      <Content>
        <Home href="/">Muetry.</Home>
        <Page>
          <Header>
            <Title>The Kaleidoscope.</Title>

            <p style={{ maxWidth: "600px", margin: '2rem auto' }}>
              I find myself fascinated by this bygone piece of technology. Its
              name roughly translates to “observation of beautiful forms” which
              is precisely why I created what you can see below. When writing,
              when thinking, I try to find alternative perspectives in which to
              view the world. I am a visual person and nothing piques my
              creativity quite like staring at the infiniteness of The
              Kaleidoscope.
            </p>
          </Header>

          <iframe
            style={{ border: 0 }}
            width="100%"
            height="900"
            src="https://jamesformica.github.io/kaleidoscope"
          />
        </Page>
      </Content>
    </Layout>
  )
}

export default Poem
